import React, { useEffect, useState } from "react";
import API from "../../../lib/api-store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "react-loader-spinner";
import ReactTooltip from "react-tooltip";
import AdminNewUserForm from "./AdminNewUserForm";
import DeleteOptions from "./DeleteOptions";
import AdminTransferOptions from "./AdminTransferOptions";
import SuccessUserAdded from "./SuccessUserAdded";
import LastDeleteWarning from "./LastDeleteWarning";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import AdminTransferForm from "./AdminTransferForm";

const AdminPage = () => {
  const [usersData, setUsersData] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [adminModal, setAdminModal] = useState(false);
  const [usersAtTierLimit, setUsersAtTierLimit] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalDisplay, setModalDisplay] = useState("user-form");
  const [userToTransfer, setUserToTransfer] = useState({
    firstName: "",
    lastName: "",
    _id: "",
  });
  const [newUserToTransfer, setNewUserToTransfer] = useState(null);
  const [method, setMethod] = useState(null);
  const toggle = () => setAdminModal(!adminModal);
  const [modalContent, setModalContent] = useState(
    <AdminNewUserForm toggle={toggle} setModalDisplay={setModalDisplay} />
  );

  useEffect(() => {
    getUsersForAdmin();
  }, []);
  const getUsersForAdmin = async () => {
    const res = await API.getUsersForAdmin();
    setUsersData(
      res.usersData.sort((a, b) => (a.name.first < b.name.first ? -1 : 1))
    );
    setCompanyData(res.companyData);
  };
  const addUserToState = (user) => {
    setUsersData(
      [user, ...usersData].sort((a, b) =>
        a.name.first < b.name.first ? -1 : 1
      )
    );
  };

  useEffect(() => {
    if (modalDisplay === "user-form") {
      return setModalContent(
        <AdminNewUserForm
          toggle={toggle}
          addUserToState={addUserToState}
          setAdminModal={setAdminModal}
          getUsersForAdmin={getUsersForAdmin}
        />
      );
    } else if (modalDisplay === "delete-options") {
      return setModalContent(
        <DeleteOptions
          selectedUser={selectedUser}
          setModalDisplay={setModalDisplay}
        />
      );
    } else if (modalDisplay === "transfer-options") {
      return setModalContent(
        <AdminTransferOptions
          usersData={usersData}
          setModalDisplay={setModalDisplay}
          userToTransfer={userToTransfer}
          setUserToTransfer={setUserToTransfer}
          setMethod={setMethod}
        />
      );
    } else if (modalDisplay === "user-transfer-form") {
      return setModalContent(
        <AdminTransferForm
          toggle={toggle}
          setModalDisplay={setModalDisplay}
          setNewUserToTransfer={setNewUserToTransfer}
        />
      );
    } else if (modalDisplay === "success-user") {
      return setModalContent(<SuccessUserAdded />);
    } else if (modalDisplay === "last-warning") {
      return setModalContent(
        <LastDeleteWarning
          toggle={toggle}
          selectedUser={selectedUser}
          userToTransfer={userToTransfer}
          newUserToTransfer={newUserToTransfer}
          method={method}
          usersData={usersData}
          setUsersData={setUsersData}
          setAdminModal={setAdminModal}
        />
      );
    }
  }, [modalDisplay]);
  useEffect(() => {
    if (usersData && companyData) {
      usersData.length >= usersAllowed(companyData.tier_status)
        ? setUsersAtTierLimit(true)
        : setUsersAtTierLimit(false);
    }
  }, [usersData, companyData]);
  useEffect(() => {
    if (!adminModal) {
      setMethod(null);
      setSelectedUser(null);
      setUserToTransfer({ firstName: "", lastName: "", _id: "" });
    }
  }, [adminModal]);

  const usersAllowed = (tier_status) => {
    if (tier_status === 0) return 0;
    if (tier_status === 1) return 1;
    if (tier_status === 2) return 3;
    if (tier_status === 90) return 1;
    if (tier_status === 91) return 3;
    return 10;
  };
  const mainContainer = companyData && (
    <div className="admin-main-container">
      <div className="admin-left-container">
        <div className="title-row">
          <div>
            <FontAwesomeIcon icon="users" /> Team Account Management
          </div>
          <div style={{ textAlign: "center" }}>
            Your plan allows{" "}
            <span className="admin-orange">
              {usersAllowed(companyData.tier_status)}
            </span>{" "}
            teammates,{" "}
            <span className="admin-orange">
              {companyData.limitations.file_storage / 1000}{" "}
            </span>
            total GB,{" "}
            <span className="admin-orange">
              {(companyData.limitations.cpu_time_per_month / 60).toFixed(1)}{" "}
            </span>{" "}
            total CPU hours, and{" "}
            <span className="admin-orange">
              {companyData.limitations.no_api > 10000
                ? "unlimited"
                : companyData.limitations.no_api}{" "}
            </span>{" "}
            APIs
          </div>
        </div>
        <div className="label-row">
          <div className="admin-label">Member Name</div>
          <div className="admin-label api">GB</div>
          <div className="admin-label">CPU Time per month</div>
          <div className="admin-label api">API</div>
        </div>
        <div className="admin-users-list">
          {usersData.map((user, index) => {
            return (
              <div className="admin-user" key={`${index}-admin`}>
                <div className="admin-user-name">
                  {`${user.name.first} ${user.name.last}`}
                  {user.admin && (
                    <span
                      style={{ paddingLeft: ".5rem" }}
                      className="admin-orange"
                    >
                      {" "}
                      Administrator
                    </span>
                  )}
                </div>
                {user.usage.file_storage ? (
                  <div>{`${user.usage.file_storage.toFixed(1)} MB used`}</div>
                ) : (
                  <div></div>
                )}
                {user.usage.cpu_time ? (
                  <div>{`${(user.usage.cpu_time / 60).toFixed(
                    1
                  )} hours used`}</div>
                ) : (
                  <div></div>
                )}
                {user.usage.no_api ? (
                  <div className="admin-api">{`${user.usage.no_api} APIs`}</div>
                ) : (
                  <div></div>
                )}
                {user.admin ? (
                  <div></div>
                ) : (
                  <div className="admin-btn-container">
                    <button
                      className="admin-delete-btn"
                      onClick={() => {
                        setSelectedUser(user);
                        setModalDisplay("delete-options");
                        toggle();
                      }}
                    >
                      DELETE
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="label-row">
          <div className="admin-last-row">Total Usage</div>
          <div className="admin-last-row">
            {(companyData.usage.file_storage / 1000).toFixed(2)} GB Used
          </div>
          <div className="admin-last-row">
            {(companyData.usage.cpu_time / 60).toFixed(1)} Hours Used
          </div>
          <div className="admin-last-row">
            {companyData.usage.no_api} API Created
          </div>
        </div>
        <div
          className="admin-button-container"
          data-tip="Maximum teammate number reached"
        >
          <button
            className={
              usersAtTierLimit
                ? "add-teammate-button disabled"
                : "add-teammate-button"
            }
            onClick={async () => {
              setModalDisplay("user-form");
              toggle();
            }}
            disabled={usersAtTierLimit}
          >
            ADD TEAMMATE
          </button>
        </div>
        {usersAtTierLimit && <ReactTooltip effect="solid" />}
      </div>
      <div className="admin-right">
        <div className="limits-box">
          <div className="limits-balance">TEAMMATES BALANCE</div>
          <div className="limits-num">
            {String(
              usersAllowed(companyData.tier_status) - usersData.length
            ).startsWith("-")
              ? 0
              : usersAllowed(companyData.tier_status) - usersData.length}
          </div>
          <div style={{ textAlign: "center" }}>
            MORE TEAMMATES <br /> ALLOWED
          </div>
        </div>
        <div className="limits-box">
          <div className="limits-balance">GB BALANCE</div>
          <div className="limits-num">
            {(
              (companyData.limitations.file_storage -
                companyData.usage.file_storage) /
              1000
            ).toFixed(1)}
          </div>
          <div>GB CREDITS REMAINING</div>
        </div>
        <div className="limits-box">
          <div className="limits-balance">CPU BALANCE</div>
          <div className="limits-num">
            {(
              (companyData.limitations.cpu_time_per_month -
                companyData.usage.cpu_time) /
              60
            ).toFixed(1)}
          </div>
          <div>CPU CREDITS REMAINING</div>
        </div>
        {companyData.limitations.no_api > 100000 ? (
          <div className="limits-box">
            <div className="limits-balance">API BALANCE</div>
            <div className="limits-num">10</div>
            <div>APIS REMAINING</div>
          </div>
        ) : null}
      </div>
    </div>
  );
  return (
    <div className="admin-page">
      <Modal isOpen={adminModal} toggle={toggle} className="admin-modal">
        <ModalHeader
          className="admin-modal-header"
          toggle={toggle}
        ></ModalHeader>
        <ModalBody>{modalContent}</ModalBody>
      </Modal>
      <div className="container">
        {companyData ? (
          mainContainer
        ) : (
          <Loader
            type="Oval"
            width={40}
            color="#0076c0"
            style={{ textAlign: "center" }}
          />
        )}
      </div>
    </div>
  );
};

export default AdminPage;
