import React from "react";
import "./Header.css";

const Header = () => (
  // <!-- Navbar STart -->
  <header id="topnav" class="topnav">
    {/* <!-- Logo container--> */}
    <div>
      <a class="logo" href="/">
        <img
          src="https://speedwiseml.herokuapp.com/static/media/SML-Logo-WordMark.a56b8698.svg"
          height="40"
          alt=""
        />
      </a>
    </div>

    <div class="buy-button">
      <a href="/login" class="btn btn-primary">
        Login
      </a>
    </div>
    {/* <!--end login button--> */}
    {/* <!-- End Logo container--> */}
    <div class="menu-extras">
      <div class="menu-item">
        {/* <!-- Mobile menu toggle--> */}
        <a class="navbar-toggle" id="isToggle" onclick="toggleMenu()">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        {/* <!-- End mobile menu toggle--> */}
      </div>
    </div>

    <div id="navigation">
      {/* <!-- Navigation Menu--> */}
      <ul class="navigation-menu">
        <li class="has-submenu">
          <a href="https://www.speedwiseml.com/home#features">FEATURES</a>
        </li>
        <li class="has-submenu">
          <a href="https://www.speedwiseml.com/home#process">PROCESS</a>
        </li>
        <li class="has-submenu">
          <a href="https://www.speedwiseml.com/home#video">VIDEO</a>
        </li>
        <li class="has-submenu">
          <a href="https://www.speedwiseml.com/home#solutions">SOLUTIONS</a>
        </li>
        <li class="has-submenu">
          <a href="https://www.speedwiseml.com/home#statistics">Statistics</a>
        </li>
        <li class="has-submenu">
          <a href="https://www.speedwiseml.com/home#casestudy">CASE STUDIES</a>
        </li>
        <li class="has-submenu">
          <a href="https://www.speedwiseml.com/Pricing">PRICING</a>
        </li>
      </ul>
      {/* <!--end navigation menu--> */}
    </div>
    {/* <!--end navigation--> */}
    {/* </div> */}
  </header>
  // <!--end header-->
  // <!-- Navbar End -->
);

export default Header;
