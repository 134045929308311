import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import login1 from "../../../static/images/login1.svg";
import login2 from "../../../static/images/login2.svg";
import aaiLogo from "../../../static/images/SML-Logo-WordMark.svg";
import queryString from "query-string";
import API from "../../../lib/api-store";

const AwsLogin = ({ user, location, history }) => {
  const [newUser, setNewUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    awsToken: "",
  });
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const handleNewUserForm = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsVerifying(true);
    const { firstName, lastName, email, company, awsToken } = newUser;
    const response = await API.resolveCustomer(
      firstName,
      lastName,
      email,
      company,
      awsToken
    );
    if (response.message) {
      setMessage(response.message);
      setIsVerifying(false);
      return;
    } else {
      setSuccess(true);
      setMessage("Success! Please check your email to login.");
    }
  };
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    setNewUser({ ...newUser, awsToken: parsed.token });
  }, []);

  if (user) {
    console.log("user is null", user);
    return <Redirect to="/" />;
  }
  return (
    <div className="login">
      <form className="login-form left" onSubmit={handleSubmit}>
        <img src={aaiLogo} className="login-product-logo" alt="" />
        <div className="login-product-header">
          Machine Learning For Everyone
        </div>
        {message && (
          <div
            className={
              success
                ? "form-notification notification-success"
                : "form-notification notification-error"
            }
          >
            <FontAwesomeIcon
              icon={success ? "check" : "exclamation-triangle"}
            />
            <span>{message}</span>
          </div>
        )}

        <input
          className="login-textbox"
          name="email"
          type="email"
          placeholder="Email"
          onChange={handleNewUserForm}
          disabled={isVerifying}
          required
        />

        <input
          className="login-textbox in-row"
          name="firstName"
          type="firstName"
          placeholder="First Name"
          onChange={handleNewUserForm}
          disabled={isVerifying}
          required
        />
        <input
          className="login-textbox in-row"
          name="lastName"
          type="lastName"
          placeholder="Last Name"
          onChange={handleNewUserForm}
          disabled={isVerifying}
          required
        />

        <input
          className="login-textbox"
          name="company"
          type="company"
          placeholder="Company"
          onChange={handleNewUserForm}
          disabled={isVerifying}
          required
        />
        <button
          className="submit-button secondary-button login-button"
          type="submit"
        >
          {isVerifying ? "VERIFIED" : "SIGN UP"}
          <FontAwesomeIcon icon="sign-in-alt" />
        </button>
        <span
          className="blue-hyperlink"
          onClick={() => {
            history.push("/");
          }}
        >
          Back to Login page
        </span>
      </form>

      <div className="img-crossfade right">
        <img className="bottom" src={login1} alt="login" />
        <img className="top" src={login2} alt="login" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.get("user") ? state.get("user").toJS() : null,
});

export default withRouter(connect(mapStateToProps)(AwsLogin));
