import React, { lazy } from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import LoginForm from "../components/Layout/User/LoginForm";
import ResetPassword from "../components/Layout/User/ResetPassword";
import MyAccount from "../components/Layout/User/MyAccount";
import Membership2 from "../components/Layout/User/Membership2";
import MembershipStripe from "../components/Layout/User/MembershipStripe";
import AdminPage from "../components/Layout/Admin/AdminPage";
import AwsLogin from "../components/Layout/User/AwsLogin";
import TrialRegistration from "../components/Layout/User/TrialRegistration";
import StripeRegistration from "../components/Layout/User/StripeRegistration";
import Payment from "../components/Layout/User/Payment";

// Product Space Pages
const Case = lazy(() => import("../components/Layout/Case/Case"));
const FaqHome = lazy(() => import("../components/Layout/Faq/FaqHome"));
const FaqCategoryPage = lazy(() =>
  import("../components/Layout/Faq/FaqCategoryPage")
);
const ExperimentsPage = lazy(() =>
  import("../components/Layout/Project/ExperimentsPage")
);
const MainLandingPage = lazy(() =>
  import("../components/Layout/User/MainLandingPage")
);
const DatasetsPage = lazy(() =>
  import("../components/Layout/Dataset/DatasetsPage")
);
const SignatureVideo = lazy(() =>
  import("../components/Layout/Dataset/SignatureVideo")
);
const ApiPage = lazy(() => import("../components/Layout/ApiPage/ApiPage"));
const DatasetPage = lazy(() =>
  import("../components/Layout/Dataset/DatasetPage")
);
const QRIAdminPage = lazy(() =>
  import("../components/Layout/Admin/QRIAdminPage")
);
const University = lazy(() => import("../components/Layout/User/University"));

const ProductSpaceRoutes = ({ user }) => (
  <React.Fragment>
    <Route exact path="/reset" component={ResetPassword} />
    <Route
      exact
      path="/login"
      render={(props) => (
        <div className="page-wrap">
          <LoginForm {...props} user={user} />
        </div>
      )}
    />
    <Route
      exact
      path="/"
      render={() => {
        window.location.href = "/home";
      }}
    />
    <Route exact path="/awslogin" user={user} component={AwsLogin} />
    <Route
      exact
      path="/trialregistration"
      user={user}
      component={TrialRegistration}
    />
    <Route exact path="/register" user={user} component={StripeRegistration} />
    <Route exact path="/payment" user={user} component={Payment} />
    <PrivateRoute exact path="/main" user={user} component={MainLandingPage} />
    <PrivateRoute exact path="/myaccount" user={user} component={MyAccount} />
    <PrivateRoute
      exact
      path="/membership"
      user={user}
      component={Membership2}
    />
    <Route exact path="/Pricing" user={user} component={MembershipStripe} />
    <PrivateRoute exact path="/experiment" user={user} component={Case} />
    <PrivateRoute exact path="/datasets" user={user} component={DatasetsPage} />
    <PrivateRoute
      exact
      path="/signaturevideo"
      user={user}
      component={SignatureVideo}
    />
    <PrivateRoute path="/dataset/:_id" user={user} component={DatasetPage} />
    <PrivateRoute
      exact
      path="/projects"
      user={user}
      component={ExperimentsPage}
    />
    <PrivateRoute exact path="/apimanage" user={user} component={ApiPage} />
    <PrivateRoute exact path="/faq" user={user} component={FaqHome} />
    <PrivateRoute exact path="/university" user={user} component={University} />
    <PrivateRoute
      path="/faq/:category"
      user={user}
      component={FaqCategoryPage}
    />
    <PrivateRoute
      path="/project/:_id"
      user={user}
      component={ExperimentsPage}
    />
    <PrivateRoute path="/qriadmin" user={user} component={QRIAdminPage} />
    <PrivateRoute path="/admin" user={user} component={AdminPage} />
  </React.Fragment>
);

export default ProductSpaceRoutes;
