export const createCheckoutSession = (productID, userData) =>
  fetch("/stripe/createCheckoutSession", {
    method: "POST",
    body: JSON.stringify({ productID, userData }),
    headers: {
      "Content-type": "application/json",
    },
  })
    .then((res) => {
      const returnRes = res.json();
      returnRes.then(function (val) {
        if (val.success == true) {
          return (window.location.href = val["stripe_checkout_url"]);
        }
      });
      // error found, return res with success == false
      return returnRes;
    })
    .catch(function (err) {
      console.info(err);
    });

export const redirectStripePortal = (customerStripeID) =>
  fetch("/stripe/redirectStripePortal", {
    method: "POST",
    body: JSON.stringify({ customerStripeID }),
    headers: {
      "Content-type": "application/json",
    },
  })
    .then((res) => {
      res.json().then(function (result) {
        window.location.href = result["stripe_portal_url"];
      });
    })
    .catch(function (err) {
      console.info(err);
    });

export const createCreditsCheckoutSession = (customerStripeID, numCredits) =>
  fetch("/stripe/createCreditsCheckoutSession", {
    method: "POST",
    body: JSON.stringify({ customerStripeID, numCredits }),
    headers: {
      "Content-type": "application/json",
    },
  })
    .then((res) => {
      const returnRes = res.json();
      returnRes.then(function (val) {
        if (val.success == true) {
          return (window.location.href = val["stripe_checkout_url"]);
        }
      });
      // error found, return res with success == false
      return returnRes;
    })
    .catch(function (err) {
      console.info(err);
    });
