export const ACCEPTABLE_SIZE_MB = 100;
export const TRIAL_USER_ACCEPTABLE_FILE_SIZE = 10;
export const handleErrors = (response) => {
  const status = response.status;
  return response.json().then((json) => {
    if (status !== 200) {
      console.log("error", json);
    }
    return json;
  });
};
