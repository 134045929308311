import * as serviceWorker from "./serviceWorker";

import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { applyMiddleware, compose, createStore } from "redux";
import { createBrowserHistory } from "history";
import {
  routerMiddleware,
  connectRouter,
  ConnectedRouter,
} from "connected-react-router/immutable";
import { Provider } from "react-redux";
import { Map } from "immutable";
import { quit } from "./lib/api-auth/api-auth";
import "./fonts/Roboto-Medium.ttf";
import "./fonts/Roboto-Bold.ttf";
import "./fonts/Roboto-Regular.ttf";

import {
  faInfoCircle,
  faInfo,
  faFilePdf,
  faKey,
  faUpload,
  faTachometerAlt,
  faStar,
  faStopCircle,
  faPlane,
  faExclamationCircle,
  faCopy,
  faExclamationTriangle,
  faCheck,
  faSignInAlt,
  faPlus,
  faArrowCircleLeft,
  faArrowCircleRight,
  faHistory,
  faTimes,
  faArrowCircleUp,
  faCircleNotch,
  faCheckCircle,
  faTimesCircle,
  faFileAlt,
  faTable,
  faBars,
  faSpinner,
  faDownload,
  faEllipsisH,
  faTrash,
  faUser,
  faHome,
  faCog,
  faHeart,
  faThLarge,
  faCompress,
  faExpand,
  faShareAlt,
  faChartBar,
  faClone,
  faUsers,
  faPencilAlt,
  faTools,
  faListAlt,
  faChartLine,
  faRedo,
  faUndo,
  faFile,
  faDatabase,
  faCalendarAlt,
  faCalendar,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faCircle,
  faSquare,
  faQuestionCircle,
  faUniversity,
  faPlusCircle,
  faMinusCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronCircleUp,
  faChevronCircleDown,
  faToggleOn,
  faToggleOff,
  faStepBackward,
  faStepForward,
  faChevronLeft,
  faChevronRight,
  faSearch,
  faClock,
  faPauseCircle,
  faPlayCircle,
  faEye,
  faPaperPlane,
  faHelicopter,
  faCloudUploadAlt,
  faFlag,
  faFlask,
  faEnvelope,
  faExternalLinkAlt,
  faCaretRight,
  faEyeSlash,
  faPen,
  faEllipsisV,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";

import App from "./components/Layout/App";
import rootReducer from "./redux/index";
import { loadState, saveState } from "./lib/local-storage";
import socketMiddleware from "./redux/socket";
import { auth } from "./lib/api-auth/api-auth";

import "./styles/app.scss";
//import 'react-table/react-table.css'
import "react-table-6/react-table.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/js/bootstrap.js';

library.add(
  faCaretRight,
  faExternalLinkAlt,
  faInfoCircle,
  faInfo,
  faFlask,
  faFilePdf,
  faKey,
  faTachometerAlt,
  faPauseCircle,
  faPlayCircle,
  faStar,
  faClock,
  faStopCircle,
  faPlane,
  faExclamationCircle,
  faCopy,
  faExclamationTriangle,
  faCheck,
  faSignInAlt,
  faPlus,
  faSquare,
  faArrowCircleLeft,
  faArrowCircleRight,
  faHistory,
  faTimes,
  faArrowCircleUp,
  faCircleNotch,
  faCheckCircle,
  faTimesCircle,
  faFileAlt,
  faTable,
  faBars,
  faSpinner,
  faDownload,
  faEllipsisH,
  faTrash,
  faUser,
  faHome,
  faCog,
  faHeart,
  faThLarge,
  faCompress,
  faExpand,
  faShareAlt,
  faChartBar,
  faClone,
  faUsers,
  faPencilAlt,
  faTools,
  faListAlt,
  faChartLine,
  faRedo,
  faUndo,
  faFile,
  faDatabase,
  faCalendarAlt,
  faCalendar,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faCircle,
  faQuestionCircle,
  faPlusCircle,
  faMinusCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronCircleUp,
  faChevronCircleDown,
  faToggleOn,
  faToggleOff,
  faStepBackward,
  faStepForward,
  faChevronLeft,
  faChevronRight,
  faSearch,
  faEye,
  faEyeSlash,
  faPaperPlane,
  faHelicopter,
  faCloudUploadAlt,
  faUpload,
  faFlag,
  faEnvelope,
  faPen,
  faEllipsisV,
  faFilter,
  faUniversity
);

const localStorageKey = "welcomeToTheMachine";
const persistedState = loadState(localStorageKey, Map());

const history = createBrowserHistory();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  connectRouter(history)(rootReducer),
  persistedState,
  composeEnhancer(
    applyMiddleware(routerMiddleware(history), socketMiddleware())
  )
);
const state = store.getState().toJS();

// On dispatch of events, stamp full state into localStorage
store.subscribe(() => {
  saveState(localStorageKey, store.getState());
});

// connected router: Synchronize router state with redux store
// through uni-directional flow (i.e. history -> store -> router -> components).
const aaiApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    document.getElementById("root")
  );
};
let timeout, isExpired;

if (state.user && state.user.loginTime) {
  timeout = moment(state.user.loginTime).add(180, "m").toDate();
  let now = new Date();
  isExpired = now > timeout;
}
if (state.user && !isExpired) {
  console.log(state.user.firstName, "logged in");
  auth(state.user)
    .then((r) => {
      store.dispatch({ type: "LOGIN", user: r });
      aaiApp();
    })
    .catch((error) => {
      console.log("error", error);
      store.dispatch({ type: "LOGOUT" }); // TODO
      aaiApp();
    });
} else if (state.user && isExpired) {
  quit();
  store.dispatch({ type: "LOGOUT" }); // TODO
  aaiApp();
} else {
  console.log("no user found in state...");
  aaiApp();
}

serviceWorker.unregister();
