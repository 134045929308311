import React, { useState, useEffect } from 'react';
import API from '../../../lib/api-store';
import Loader from 'react-loader-spinner';


const LastDeleteWarning = ({ toggle, method, userToTransfer, newUserToTransfer, selectedUser, usersData, setUsersData, setAdminModal }) => {
    const [message, setMessage] = useState('');
    const [clicked, setClicked] = useState(false)
    useEffect(() => {
        if (method === 'transfer') {
            setMessage(`Please confirm deletion of ${selectedUser.name.first} ${selectedUser.name.last} and transfering user data to ${userToTransfer.firstName} ${userToTransfer.lastName}`)
        } else if (method === 'newUser') {
            setMessage(`Please confirm deletion of ${selectedUser.name.first} ${selectedUser.name.last} and transfering user data to ${newUserToTransfer.firstName} ${newUserToTransfer.lastName}`)
        } else {
            setMessage('Deletion of user will delete all datasets, workspaces, and models owned by the user')
        }
    }, [])
    return (<div className='last-warning-container'>
        <h4>Are you sure?</h4>
        <div>{message}</div>
        {
            clicked ? <Loader type="Oval" width={40} color="#0076c0" style={{ textAlign: 'center' }} /> : <div className='last-warning-row'>
                <button className='cancel' onClick={toggle}>Cancel</button>
                <button className='delete' onClick={() => {
                    setClicked(true)
                    if (method) {
                        if (method === 'transfer') {
                            return API.transferToExisting(selectedUser._id, userToTransfer._id).then(r => {
                                setClicked(false)
                                if (r.result.userMongoRemove.deletedCount === 1) {
                                    const output = usersData.reduce((acc, ele) => {
                                        if (ele._id === selectedUser._id) return acc
                                        if (ele._id === r.result.targetUser._id) return [r.result.targetUser, ...acc]
                                        return [ele, ...acc]
                                    }, []).sort((a, b) => a.name.first < b.name.first ? -1 : 1)
                                    setUsersData(output)
                                }
                                setAdminModal(false)
                            })
                        }
                        return API.transferToNew(selectedUser._id, newUserToTransfer).then(r => {
                            setClicked(false)
                            if (r.result.emailResponse.accepted) {
                                const output = [r.result.res, ...usersData.reduce((acc, ele) => {
                                    if (ele._id === selectedUser._id) return acc
                                    return [ele, ...acc]
                                }, [])].sort((a, b) => a.name.first < b.name.first ? -1 : 1)
                                setUsersData(output)
                            }
                            setAdminModal(false)
                        })
                    }
                    return API.deleteUser(selectedUser._id).then(r => {
                        console.log('r', r)
                        setClicked(false)
                        if (r.result.success) {
                            setUsersData(usersData.filter(x => x._id !== selectedUser._id))
                        }
                        setAdminModal(false)
                    })
                }}>Delete</button>
            </div>
        }
    </div>)
}

export default LastDeleteWarning;