// import io from 'socket.io-client'

export default () => storeAPI => next => (action) => {
  // let socket
  if (action.type === 'LOGIN') {
    // socket = io()
    // socket.on('ml_logger', (message) => {
    //   console.log('i got message please dispatch', message.projectId)
    //   storeAPI.dispatch({
    //     type: 'MACHINE_LEARNING_LOG_RECEIVED',
    //     payload: message,
    //   })
    // })
  }
  if (action.type === 'SEND_WEBSOCKET_MESSAGE') {
    // TODO: DO SOMETHING HERE
  }
  return next(action)
}
