import React from "react";
import "./Footer.css";

const Footer = () => (
  <footer class="footer2">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
          <a href="#" class="logo-footer">
            <img
              src="images/QRI-Vector_Wordmark-SQ.png"
              height="124"
              alt="Quantum Reservoir Impact, LLC (QRI)"
            />
          </a>
          <p class="mt-4">
            SpeedWise<sup>&reg;</sup> ML is a product of QRI.
          </p>
          <ul class="list-unstyled social-icon social mb-0 mt-4" />
        </div>
        <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-sm-0">
          <h5 class="text-blue footer-head">Company</h5>
          <ul class="list-unstyled footer-list mt-4">
            <li>
              <a
                href="https://www.qrigroup.com/"
                target="_blank"
                class="text-foot"
              >
                About us
              </a>
            </li>
            <li>
              <a
                href="https://www.qrigroup.com/what-we-do"
                target="_blank"
                class="text-foot"
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="https://www.qrigroup.com/terms-of-use"
                target="_blank"
                class="text-foot"
              >
                Terms of Services
              </a>
            </li>
            <li>
              <a
                href="https://www.qrigroup.com/terms-of-use"
                target="_blank"
                class="text-foot"
              >
                {" "}
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-sm-0"></div>
        <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-sm-0">
          <h5 class="text-blue footer-head">
            <a
              href="https://www.linkedin.com/in/speedwise-machine-learning-8bbb13204/"
              target="_blank"
            >
              Follow Us on LinkedIn
            </a>
          </h5>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
