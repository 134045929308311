import React from "react";
import { connect } from "react-redux";
import { openSupport } from "../../../redux/actions";
import header_logo from "../../../static/images/SML-Logo-WordMark.a56b8698.svg";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Footer from "../../General/Footer";
import Header from "../../General/Header";
import API from "../../../lib/api-store";

const MembershipStripe = ({ openSupportAction, user }) => {
  const history = useHistory();
  const attributes = [
    {
      attribute: "Autopilot Mode and Uncertainty Quantification",
      trial: "checkmark",
      individual: "checkmark",
      team: "checkmark",
      business: "checkmark",
    },
    {
      attribute: "Total Number of users per account",
      trial: 1,
      individual: 1,
      team: 3,
      business: 10,
    },
    {
      attribute: "Total CPU hours per month",
      trial: 10,
      individual: 30,
      team: 90,
      business: 300,
    },
    {
      attribute: "Total API Deployment",
      trial: 3,
      individual: 100,
      team: "1,000",
      business: "10,000",
    },
    {
      attribute: "Total storage for raw data(GB)",
      trial: "",
      individual: 5,
      team: 15,
      business: 50,
    },
    {
      attribute: "Private Dataset (Based on GB storage)",
      trial: "",
      individual: "checkmark",
      team: "checkmark",
      business: "checkmark",
    },
    {
      attribute: "Price per User per month",
      trial: "$50",
      individual: "$100",
      team: "$1,000,000",
      business: "$2,000,000",
    },
  ];

  const handleCreditsCheckoutSession = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (user.stripeUser) {
      const numCredits = e.target.getAttribute("value");
      const customerStripeID = user.stripe_customer_identifier;
      console.log("stripe id: " + user.stripe_customer_identifier);
      console.log("number of credits: " + numCredits);

      API.createCreditsCheckoutSession(customerStripeID, numCredits).then(
        (r) => {
          if (r.success) {
            // setSuccess(true);
            // setIsBusy(false);
            // setIsRedirected(true);
            console.log("createCreditsCheckoutSession SUCCESS");
          }
          if (r.error) {
            console.log("createCreditsCheckoutSession ERROR");
            // console.log(r.error);
            // setSuccess(false);
            // setMessage(<span dangerouslySetInnerHTML={{ __html: r.error }}></span>);
            // setIsBusy(false);
          }
        }
      );
    }
  };
  return (
    <div>
      <Header />
      <div className="membership2">
        <div className="container">
          {/* ADD NEW PRICING DESIGN */}
          {/* <!--start pricing--> */}
          <section class="bg-pricing-11">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-6">
                  <div class="logo">
                    <img src={header_logo} />
                  </div>
                  <div class="text-center mb-5">
                    <h3 class="text-capitalize">Pricing and Plans</h3>
                    <p class="text-muted">
                      You're so close to accessing state-of-the-art Machine
                      Learning at your finger tips. <br />
                      <a
                        href="https://www.speedwiseml.com/trialregistration"
                        target="_self"
                      >
                        <span class="trial-link">14-Day Free Trial!</span>
                      </a>
                      No credit card required.
                    </p>
                  </div>
                </div>
                {/* <!--end col--> */}
              </div>
              {/* <!--end row--> */}
              <div class="row justify-content-center align-items-center">
                <div class="col-lg-3 col-md-6">
                  <div class="pricing-box price-eff card text-center mt-3 pricing-box-personal">
                    <div class="pricing-title p-4">
                      <h6 class="text-uppercase mb-0">Personal</h6>
                    </div>
                    <div class="price p-3">
                      <h1 class="mb-0 fw-normal">
                        <sup>$</sup>50<sub class="h6 fw-normal">Month</sub>
                      </h1>
                    </div>
                    <ul class="card-body pricing-content list-unstyled mb-0">
                      <li>Single user license</li>
                      <li>Unlimited access</li>
                      <li>5 model training hours per month</li>
                      <li>Email support</li>
                    </ul>
                    {/* <!--end card-body--> */}
                    <div class="p-4">
                      <Link
                        class="btn btn-outline-primary"
                        to={{
                          pathname: "/register",
                          state: { productID: "STRIPE_PRODUCT_PERSONAL" },
                        }}
                      >
                        Buy Now
                      </Link>
                    </div>
                  </div>
                  {/* <!--end pricing-box--> */}
                </div>
                {/* <!--end col--> */}

                <div class="col-lg-3 col-md-6">
                  {/* <div class="card text-center mt-3"> */}
                  <div class="pricing-active pricing-box price-eff card text-center mt-3 pricing-box-pro">
                    <div class="pricing-badge">
                      <span
                        id="most-pop-badge"
                        class="badge bg-primary fw-normal fs-8 text-center"
                        style={{ backgroundColor: "#cd5747 !important" }}
                      >
                        Most Popular!
                      </span>
                    </div>
                    <div class="pricing-title p-4">
                      <h6 class="text-uppercase mb-0">Professional</h6>
                    </div>
                    <div class="price p-3 bg-primary">
                      <h1 class="mb-0 fw-normal">
                        <sup>$</sup>300
                        <sub class="h6 fw-normal">Month</sub>
                      </h1>
                    </div>
                    <ul class="card-body pricing-content list-unstyled mb-0">
                      <li>Optional team license</li>
                      <li>Collaborative features</li>
                      <li>Unlimited access</li>
                      <li>30 model training hours per month</li>
                      <li>Email support</li>
                    </ul>
                    {/* <!--end card-body--> */}
                    <div class="p-4">
                      <Link
                        class="btn btn-primary"
                        to={{
                          pathname: "/register",
                          state: { productID: "STRIPE_PRODUCT_PRO" },
                        }}
                      >
                        Buy Now
                      </Link>
                    </div>
                  </div>
                  {/* <!--end pricing-box--> */}
                </div>
                {/* <!--end col--> */}

                <div class="col-lg-3 col-md-6">
                  <div class="pricing-box price-eff card text-center mt-3 pricing-box-contact">
                    <div class="pricing-title p-4">
                      <h6 class="text-uppercase mb-0">Enterprise</h6>
                    </div>
                    <div class="price p-3 price-banner-contact">
                      <h4 class="mb-0 fw-normal price-banner-contact-text">
                        Ask us for a quote
                      </h4>
                    </div>
                    <ul class="card-body pricing-content list-unstyled mb-0">
                      <li>Customized to fit your needs and your team</li>
                      <li>300+ model training hours per month</li>
                    </ul>
                    {/* <!--end card-body--> */}
                    <div class="p-4">
                      <a
                        href="mailto:speedwise.ml@qrigroup.com"
                        class="btn btn-outline-primary"
                      >
                        Contact Us
                      </a>
                    </div>
                  </div>

                  {/* <!--end pricing-box--> */}
                </div>
                {/* <!--end col--> */}
              </div>
              {/* <!--end row--> */}
            </div>
            {/* START - sample buy button for 2 credits */}
            {/* {user && user.stripeUser && (
              <Link
                class="btn btn-primary"
                onClick={handleCreditsCheckoutSession}
                value="2"
              >
                Buy 2 Credits
              </Link>
            )} */}
            {/* END - sample buy button for 2 credits */}
            {/* <!--end container--> */}
          </section>
          {/* <!--end pricing--> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { user: state.get("user") ? state.get("user").toJS() : null };
};

const mapDispatchToProps = (dispatch) => ({
  openSupportAction: (topic) => dispatch(openSupport(topic)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipStripe);
