import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Redirect } from "react-router-dom";
import { login } from "../../../redux/actions";
import {
  auth,
  sendResetEmail,
  createAccount,
} from "../../../lib/api-auth/api-auth";
import login1 from "../../../static/images/login1.svg";
import login2 from "../../../static/images/login2.svg";
import aaiLogo from "../../../static/images/SML-Logo-WordMark.svg";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ReactMarkdown from "react-markdown";
import privacyMarkdown from "../../../static/legal/privacy.md";
import serviceMarkdown from "../../../static/legal/ToS.md";

function parseQuery(queryString) {
  const query = {};
  const pairs = (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&");
  pairs.forEach((elem) => {
    const pair = elem.split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  });
  return query;
}

const ModalPrivacyNotice = ({ showPrivacyModal, togglePrivacyModal }) => {
  const [markdown, setMarkdown] = useState("");
  useEffect(() => {
    fetch(privacyMarkdown)
      .then((r) => r.text())
      .then((text) => {
        setMarkdown(text);
      });
  }, []);
  return (
    <Modal
      isOpen={showPrivacyModal}
      toggle={togglePrivacyModal}
      className="privacy-modal"
    >
      <ModalHeader toggle={togglePrivacyModal}>
        Privacy Policy of SpeedWise<sup>&reg;</sup> ML by QUANTUM RESERVOIR
        IMPACT, LLC
      </ModalHeader>
      <ModalBody>
        <ReactMarkdown escapeHtml={false} source={markdown} />
      </ModalBody>
    </Modal>
  );
};
const ModalServiceNotice = ({ showServiceModal, toggleServiceModal }) => {
  const [markdown, setMarkdown] = useState("");
  useEffect(() => {
    fetch(serviceMarkdown)
      .then((r) => r.text())
      .then((text) => {
        setMarkdown(text);
      });
  }, []);
  return (
    <Modal
      isOpen={showServiceModal}
      toggle={toggleServiceModal}
      className="privacy-modal"
    >
      <ModalHeader toggle={toggleServiceModal}>
        Terms of Service <br />
        SpeedWise<sup>&reg;</sup> ML ("SML")
        <br />
        QRI AI, LLC
      </ModalHeader>
      <ModalBody>
        <ReactMarkdown escapeHtml={false} source={markdown} />
      </ModalBody>
    </Modal>
  );
};
class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVerifying: false,
      isInvalid: false,
      isInactive: false,
      isNewUser: false,
      hasSentFirstEmail: false,
      shouldDisplayCreateAccount: false,
      hasSentResetEmail: false,
      shouldDisplayForgotPassword: false,
      isSendingEmail: false,
      attemptsLeft: 5,
      trialExpired: false,
      error: false,
      errorMessage: "",
      showPrivacyModal: false,
      showServiceModal: false,
    };
    this.userEmail = React.createRef();
    this.userPassword = React.createRef();
    this.resetEmail = React.createRef();
    this.userFirstName = React.createRef();
    this.userLastName = React.createRef();
    this.userCompany = React.createRef();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    this.welcomeFlag = params.get("msg") == "welcome";
  }

  componentDidMount() {
    // this.attemptLogin()
  }

  attemptLogin = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { loginAction } = this.props;
    const email = this.userEmail.current.value;
    const password = this.userPassword.current.value;
    const user = { email, password };
    this.setState({ isVerifying: true });
    auth(user)
      .then((r) => {
        if (r.error) {
          if (r.message === "Trial duration exceeded. Please contact support") {
            return this.setState({
              isInvalid: true,
              trialExpired: true,
              isVerifying: false,
            });
          }
          return this.setState({
            isInvalid: true,
            isInactive: true,
            isVerifying: false,
          });
        }
        this.setState({ isInvalid: false }, () => loginAction(r));
      })
      .catch((error) => {
        const attempts = error.toString().match(/[0-9]+/);
        const attemptsLeft = 5 - attempts;
        this.setState({ isVerifying: false, isInvalid: true, attemptsLeft });
      });
  };

  sendForgotPassword = (e) => {
    e.preventDefault();
    const resetEmail = this.resetEmail.current.value;

    this.setState({ isSendingEmail: true }, () => {
      sendResetEmail(resetEmail).then((r) => {
        if (r.success) {
          this.setState({
            hasSentResetEmail: true,
            isSendingEmail: false,
            error: false,
            errorMessage: "",
          });
        } else {
          this.setState({
            hasSentResetEmail: false,
            error: true,
            errorMessage: "Account does not exist!",
            isSendingEmail: false,
          });
        }
      });
    });
  };

  renderForgotPassword = () => {
    const { hasSentResetEmail, isSendingEmail, error, errorMessage } =
      this.state;

    return (
      <form className="login-form left" onSubmit={this.sendForgotPassword}>
        <img src={aaiLogo} className="login-product-logo" alt="" />
        <div className="login-product-header">Reset Password</div>
        {error && (
          <div className="form-notification notification-error">
            <FontAwesomeIcon icon="exclamation-triangle" />
            <span>{errorMessage}</span>
          </div>
        )}
        {hasSentResetEmail && (
          <div className="form-notification notification-success">
            <FontAwesomeIcon icon="check" />
            <span>
              Please check your email inbox (also the spam folder just in case)
            </span>
          </div>
        )}
        <p className="instructions">
          {`To reset your password, enter your email below and submit.
		  An email will be sent to you with further instructions.`}
        </p>
        <input
          className="login-textbox"
          name="email"
          type="email"
          placeholder="Email"
          ref={this.resetEmail}
          required
        />
        <button
          className="submit-button secondary-button login-button"
          type="submit"
          disabled={hasSentResetEmail || isSendingEmail}
        >
          Reset Password
          <FontAwesomeIcon icon="sign-in-alt" />
        </button>
        <button
          onClick={() => this.setState({ shouldDisplayForgotPassword: false })}
          type="button"
          className="forgot-password"
        >
          Back to sign in
        </button>
      </form>
    );
  };

  sendCreateAccount = (e) => {
    if (e) {
      e.preventDefault();
    }
    const email = this.userEmail.current.value;
    const firstName = this.userFirstName.current.value;
    const lastName = this.userLastName.current.value;
    const company = this.userCompany.current.value;
    // trial is harded coded as true for now, JM 3/3/2020
    const newUser = {
      email,
      firstName,
      lastName,
      company,
      urlOrigin: window.location.origin.toString(),
      trial: true,
    };

    this.setState({ isVerifying: true, isSendingEmail: true });
    createAccount(newUser)
      .then((r) => {
        if (r.error) {
          return this.setState({
            error: true,
            errorMessage: r.error,
            isVerifying: false,
          });
        }
        this.setState({
          isVerifying: false,
          hasSentFirstEmail: true,
          isSendingEmail: false,
        });
      })
      .catch((error) => {
        this.setState({
          isVerifying: false,
          isInvalid: true,
          isSendingEmail: false,
        });
      });
  };

  renderNewUser = () => {
    const { hasSentFirstEmail, isSendingEmail, error, errorMessage } =
      this.state;
    return (
      <form className="login-form left" onSubmit={this.sendCreateAccount}>
        <img src={aaiLogo} className="login-product-logo" alt="" />
        <div className="login-product-header">Request a Free Trial</div>
        {hasSentFirstEmail && (
          <div className="form-notification notification-success">
            <FontAwesomeIcon icon="check" />
            <span>
              Please check your email inbox (also the spam folder just in case)
            </span>
          </div>
        )}
        {error && (
          <div className="form-notification notification-error">
            <FontAwesomeIcon icon="exclamation-triangle" />
            <span>{errorMessage}</span>
          </div>
        )}
        <p className="instructions">
          {`To create an account, enter your information below.
        A verification email will be sent to you with a link to set your password.`}
        </p>
        <input
          className="login-textbox"
          name="email"
          type="email"
          placeholder="Email"
          ref={this.userEmail}
          required
        />
        <input
          className="login-textbox"
          name="firstName"
          type="text"
          placeholder="First Name"
          ref={this.userFirstName}
          required
        />
        <input
          className="login-textbox"
          name="lastName"
          type="text"
          placeholder="Last Name"
          ref={this.userLastName}
          required
        />
        <input
          className="login-textbox"
          name="company"
          type="text"
          placeholder="Company"
          ref={this.userCompany}
          required
        />
        <button
          className="submit-button secondary-button login-button"
          type="submit"
          disabled={hasSentFirstEmail || isSendingEmail}
        >
          Create Account
          <FontAwesomeIcon icon="sign-in-alt" />
        </button>
        <button
          onClick={() =>
            this.setState({ error: false, shouldDisplayCreateAccount: false })
          }
          type="button"
          className="forgot-password"
        >
          Back to sign in
        </button>
      </form>
    );
  };

  render() {
    const {
      isVerifying,
      shouldDisplayForgotPassword,
      isInvalid,
      shouldDisplayCreateAccount,
      attemptsLeft,
      trialExpired,
      isInactive,
    } = this.state;
    const { user, location: { search } = undefined } = this.props;
    if (user !== null) {
      if (user.last_access_date) {
        return <Redirect to="/main" />;
      }
      return <Redirect to="/datasets" />; //default landing page
    }
    const query = parseQuery(search);
    let comp = null;
    if (shouldDisplayForgotPassword) {
      comp = this.renderForgotPassword();
    } else if (shouldDisplayCreateAccount) {
      comp = this.renderNewUser();
    } else {
      comp = (
        <form className="login-form left" onSubmit={this.attemptLogin}>
          <img src={aaiLogo} className="login-product-logo" alt="" />
          {this.welcomeFlag && (
            <div class="alert alert-success" role="alert">
              <p class="mb-0">Success! Please check your email to login.</p>
            </div>
          )}
          <div className="login-product-header">
            Machine Learning For Everyone
          </div>
          {isInvalid && attemptsLeft >= 1 && !trialExpired && !isInactive && (
            <div className="form-notification notification-error">
              <FontAwesomeIcon icon="exclamation-triangle" />
              <span>
                Invalid Login Information Attempts Remaining:{" "}
                {JSON.stringify(attemptsLeft)}
              </span>
            </div>
          )}
          {isInvalid && trialExpired && (
            <div className="form-notification notification-error">
              <FontAwesomeIcon icon="exclamation-triangle" />
              <span>Trial duration expired, please contact support.</span>
            </div>
          )}
          {isInvalid && isInactive && (
            <div className="form-notification notification-error">
              <FontAwesomeIcon icon="exclamation-triangle" />
              <span>Please contact QRI support.</span>
            </div>
          )}
          {isInvalid && attemptsLeft < 1 && (
            <div className="form-notification notification-error">
              <FontAwesomeIcon icon="exclamation-triangle" />
              Invalid Login Information
              <br />
              No Attempts Remaining!
              <br />
              Please Reset Your Password
            </div>
          )}
          {query.password_reset_expired && (
            <div className="form-notification notification-error">
              <FontAwesomeIcon icon="exclamation-triangle" />
              <span>Password Reset Expired</span>
            </div>
          )}
          {query.password_reset_success && (
            <div className="form-notification notification-success">
              <FontAwesomeIcon icon="check" />
              <span>Password successfully reset</span>
            </div>
          )}
          <input
            className="login-textbox"
            name="email"
            type="email"
            placeholder="Email"
            ref={this.userEmail}
            disabled={isVerifying}
            required
          />
          <input
            className="login-textbox"
            name="password"
            type="password"
            placeholder="Password"
            ref={this.userPassword}
            disabled={isVerifying || attemptsLeft < 1}
            required
          />
          <div className="privacy-notice">
            <FontAwesomeIcon
              icon="info-circle"
              className="privacy-notice-icon"
            />
            This website uses cookies and logs your activities to make your
            experience better. By clicking "Login" you are agreeing on our
            <span
              className="view-privacy"
              onClick={() => {
                this.setState({ showPrivacyModal: true });
              }}
            >
              Privacy Policy &nbsp;
            </span>
            and
            <span
              className="view-privacy"
              onClick={() => {
                this.setState({ showServiceModal: true });
              }}
            >
              Terms of Service &nbsp;
            </span>
            of QRI AI, LLC.
          </div>
          <button
            className="submit-button secondary-button login-button"
            type="submit"
            disabled={isVerifying || attemptsLeft < 1}
          >
            {isVerifying ? "VERIFYING..." : "LOGIN"}
            <FontAwesomeIcon icon="sign-in-alt" />
          </button>

          <button
            onClick={() =>
              this.setState({
                shouldDisplayForgotPassword: true,
                hasSentResetEmail: false,
              })
            }
            type="button"
            className="forgot-password"
          >
            Forgot your password?
          </button>
          {/* <button
						onClick={() =>
							this.setState({
								shouldDisplayCreateAccount: true,
								hasSentFirstEmail: false
							})}
						type="button"
						className="forgot-password"
					>
						Request a free trial account
					</button> */}
        </form>
      );
    }

    return (
      <div className="login">
        <ModalPrivacyNotice
          showPrivacyModal={this.state.showPrivacyModal}
          togglePrivacyModal={() => {
            this.setState({ showPrivacyModal: !this.state.showPrivacyModal });
          }}
        />
        <ModalServiceNotice
          showServiceModal={this.state.showServiceModal}
          toggleServiceModal={() => {
            this.setState({ showServiceModal: !this.state.showServiceModal });
          }}
        />
        {comp}
        <div className="footer">
          <div>
            This software is provided by QRI Group, Visit{" "}
            <a href="https://www.qrigroup.com" target="_blank">
              us
            </a>
            .
          </div>
          <div>
            Contact us:{" "}
            <a href="mailto:speedwise.ml@qrigroup.com">
              speedwise.ml@qrigroup.com
            </a>
          </div>
          <div> &nbsp; </div>
          <div>© 2022 Quantum Reservoir Impact, LLC.</div>
        </div>
        <div className="img-crossfade right">
          <img className="bottom" src={login1} alt="login" />
          <img className="top" src={login2} alt="login" />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loginAction: (user) => dispatch(login(user)),
});
export default connect(null, mapDispatchToProps)(LoginForm);
