import { handleErrors } from "./errors";
import { downloadFile, getNewColName } from "./helpers";
import io from "socket.io-client";

const socket = io();

const getAddress = (url, ...params) => {
  if (typeof url === "string" || url instanceof String) {
    return url;
  } else {
    return url(...params);
  }
};

const post = (url, body, ...params) =>
  fetch(getAddress(url, ...params), {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  }).then(handleErrors);

const put = (url, body, ...params) =>
  fetch(getAddress(url, ...params), {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  }).then(handleErrors);

const get = (url, ...params) =>
  fetch(getAddress(url, ...params)).then(handleErrors);

const del = (url, ...params) =>
  fetch(getAddress(url, ...params), {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
    },
    credentials: "include",
  }).then(handleErrors);

// admin routes
const getAllUsers = () => get("/core/get_all_users", {});
const getAllUsersAnon = () => get("/core/get_all_users_anon", {});
const updateActive = (userId, boolean) =>
  post("/core/update_active", { userId, boolean });
const getUsersForAdmin = () => get("/core/get_users_for_admin", {});
const createNewUser = (
  email,
  firstName,
  lastName,
  company,
  companyId,
  phone,
  tier,
  includeTrialDataset,
  admin,
  isTrialPage = false,
  region = null
) =>
  post("/auth/create_user_account", {
    email,
    firstName,
    lastName,
    company,
    companyId,
    phone,
    tier,
    includeTrialDataset,
    admin,
    isTrialPage,
    region,
  });
const deleteUser = (userId) => del(`/core/delete_user?userId=${userId}`, {});
const changeTier = (companyId, tier) =>
  post("/core/change_tier", { companyId, tier });
const extendTrial = (userId) => post("/core/extend_trial", { userId });
const transferToExisting = (userId, userToTransferTo) =>
  post("/core/transfer_to_existing", { userId, userToTransferTo });
const transferToNew = (userId, newUser) =>
  post("/core/transfer_to_new", { userId, newUser });
const changePassword = (userId, password) =>
  post("/core/change_password", { userId, password });
const addVideoToUser = (videoName) =>
  post("/core/add_video_to_user", { videoName });

// auth routes
const auth = (user) => post("/auth/login", { user }); // these are not compatible with backend!!
const createAccount = (newUser) => post("/auth/create_account", { newUser });
const sendResetEmail = (email) =>
  post("/auth/request_reset_password", { email });
const resetPassword = (pass, id) => post("/auth/reset_password", { pass, id });
const checkResetExpiration = (query) =>
  post(`/auth/reset_expired?id=${query}`, {});
const fetchAdminData = () => get("/auth/fetch_admin_data");
const supportEmail = (email, msg, topic) =>
  post(`/auth/support_email`, { email, msg, topic });
const changeName = (userId, names) =>
  post("/auth/change_name", { userId, names });
const checkUsage = (userId) => get(`/auth/check_usage?id=${userId}`, {});
const resolveCustomer = (firstName, lastName, email, company, awsToken) =>
  post(`/auth/resolve_customer`, {
    firstName,
    lastName,
    email,
    company,
    awsToken,
  });

// S3 routes
// const getFileUploadSignedUrl = (file) => post('/dataset/file', { file });
// const updateFileMeta = (obj) => put('/dataset/file', { obj });
const downloadFileFromS3 = (key, name) =>
  get(`/aws/s3_url?key=${encodeURIComponent(key)}`).then((r) =>
    downloadFile(r.url, name)
  );

// SQS routes
const createQueue = (qId) => post("/aws/createQ", { qId });
const sendMessage = (msg, qName) => post("/aws/send", { msg, qName });
const receiveMessage = (qName) => post("/aws/receive", { qName });

// STRIPE routes
const createCheckoutSession = (productID, userData) =>
  post("/stripe/createCheckoutSession", { productID, userData });
const redirectStripePortal = (customerStripeID) =>
  post("/stripe/redirectStripePortal", { customerStripeID });
const createCreditsCheckoutSession = (customerStripeID, numCredits) =>
  post("/stripe/createCreditsCheckoutSession", {
    customerStripeID,
    numCredits,
  });

// project routes
const enterProject = (projectId, datasetId) =>
  post("/project/enterProject", { projectId, datasetId });
const createProject = (
  projectName,
  projectDescription,
  datasetId,
  datasetName
) =>
  post("/project/create_project", {
    projectName,
    projectDescription,
    datasetId,
    datasetName,
  });
const hideProject = (projectId, boolean) =>
  post("/project/hide_project", { projectId, boolean });
const unsubscribeProject = (projectId) =>
  del(`/project/unsubscribe_project?projectId=${projectId}`, {});
const deleteProject = (userId, projectId) =>
  del(`/project/delete_project?projectId=${projectId}&userId=${userId}`, {});
const getUsersInProject = (userId) =>
  get(`/core/get_users?userId=${userId}`, {});
const editProject = (projectId, projectName, projectDescription) =>
  post("/project/edit_project", { projectId, projectName, projectDescription });

// core routes
const fetchDataAfterLogin = () => get("/core/fetch_data_after_login", {});
const fetchDataForLandingPage = () =>
  get("/core/fetch_data_for_landing_page", {});
const fetchActivities = (pageSize, pageIndex, option) =>
  post("/core/fetch_activities", { pageSize, pageIndex, option });

// case routes
const enterCase = (caseId) => post("/case/enterCase", { caseId });
const getCaseInfo = (stepName, caseId, datasetId) =>
  post("/case/info", { stepName, caseId, datasetId });
const editCase = (userId, caseId, caseName, caseDescription) =>
  post("/case/edit_case", { userId, caseId, caseName, caseDescription });
const initializeCase = (
  caseId,
  datasetId,
  selectedFileId,
  selectedTargetColumn,
  selectedProblemType,
  change = null
) =>
  post("/case/initialize", {
    caseId,
    datasetId,
    selectedFileId,
    selectedTargetColumn,
    selectedProblemType,
    change,
  });
const preprocConfigQC = (opName, opConfig) =>
  post("/case/preprocConfigQC", { opName, opConfig });
const preprocOpExecution = (opName, opConfig) =>
  post("/case/preprocOpExecution", { opName, opConfig });
const preprocUndo = () => post("/case/preprocUndo", {});
const preprocRedo = () => post("/case/preprocRedo", {});
const preprocRestore = (opIndex) => post("/case/preprocRestore", { opIndex });
const preprocRecommendation = () => post("/case/preprocRecommendation", {});
const preprocAutopilot = (caseId) => post("/case/preprocAutopilot", { caseId });
const editTableData = (modifiedValues) =>
  post("./case/editTableData", { modifiedValues });
const fetchTableData = (
  pageSize,
  pageIndex,
  filters,
  sortBy,
  searchText,
  searchKey
) =>
  post("./case/fetchdata", {
    pageSize,
    pageIndex,
    filters,
    sortBy,
    searchText,
    searchKey,
  });
const fetchDataforPpplViz = (caseId, chartType, config) =>
  post("./case/fetchdataforViz", { caseId, chartType, config });
const fetchSampleData = (s3Loc, pageSize, pageIndex, filters, sortBy) =>
  post("./case/fetchsampledata", {
    s3Loc,
    pageSize,
    pageIndex,
    filters,
    sortBy,
  });
const preprocPostProcessing = () => get("/case/preprocPostProcessing");
const updateModelEvalMetric = (metric) =>
  post("/case/updateEvalMetric", { metric });
const createModel = (isEnsemble, method) =>
  post("/case/createModel", { isEnsemble, method });
const deleteModel = (modelId) => post("/case/deleteModel", { modelId });
const getModelInfo = (modelId, basic) =>
  post("case/modelinfo", { modelId, basic });
const startTuning = (modelId, isAuto, hyperparametersTuningConfig) =>
  post("case/startTuning", { modelId, isAuto, hyperparametersTuningConfig });
const stopTuning = (modelId) => post("case/stopTuning", { modelId });
const pauseTuning = (modelId) => post("case/pauseTuning", { modelId });
const resumeTuning = (modelId) => post("case/resumeTuning", { modelId });
const ensembleModel = (modelIds, ensembleMethod) =>
  post("case/ensembleModel", { modelIds, ensembleMethod });
const selectModel = (modelId) => post("case/selectModel", { modelId });
const getDetailsForEvaluation = (modelId) =>
  post("case/getDetailsForEvaluation", { modelId });
const getDetailsForExplanation = () =>
  post("case/getDetailsForExplanation", {});
const getDetailsForUq = (uqModelId) =>
  post("case/getDetailsForUq", { uqModelId });
const getCurrentCaseStep = (caseId) =>
  get(`case/getCurrentStep?caseId=${caseId}`, {});
const explainModel = (nSamples) => post("case/explainModel", { nSamples });
const quantifyUncertainty = (nJobs) =>
  post("case/quantifyUncertainty", { nJobs });
const preUploadPredictionFile = (file) =>
  post("case/uploadPredictionFile", file);
const postUploadPredictionFile = (file) =>
  put("case/uploadPredictionFile", file);
const getPredictionFiles = () => post("case/getPredictionFiles", {});
const deletePredictionFile = (fileId) =>
  post("case/deletePredictionFile", { fileId });
const predict = (fileLocation, fileId) =>
  post("case/predict", { fileLocation, fileId });
const generateReport = (setting, userId, caseId, datasetId, projectId) =>
  post("case/report", { setting, userId, caseId, datasetId, projectId });
const deleteReport = (loc) => post("case/deleteReport", { loc });
const enableAPI = (expirationDate, caseId = null, datasetId, projectId) =>
  post("case/enableAPI", { expirationDate, caseId, datasetId, projectId });
const disableAPI = () => post("case/disableAPI", {});
const getApiforApiPage = (userId) => get("case/getApiForApiPage", { userId });
const deleteApi = (apiId) => del(`case/delete_api?apiId=${apiId}`, {});
const deleteCase = (caseId, projectId) =>
  post("/case/delete_case", { caseId, projectId });
const getCollections = (userId) =>
  get(`/case/get_collections?userId=${userId}`, {});
const updateFavoriteCase = (caseId, isfavorite) =>
  post("/case/update_favorite_case", { caseId, isfavorite });
const createNewCase = (userId, name, description, projectId, datasetId) =>
  post("/case/create", { userId, name, description, projectId, datasetId });
const finalizeCase = (caseId, projectId, userId) =>
  post("case/finalize", { caseId, projectId, userId });
const updateIsShared = (caseId) => post("/case/update_isshared", { caseId });
const ppplGetValuesForConfig = (operation, singleColToTransform) =>
  post("/case/ppplGetValuesForConfig", { operation, singleColToTransform });
const duplicateCase = (caseId, projectId) =>
  post("/case/duplicate_case", { caseId, projectId });
const predictOne = (datasetId, caseId, features) =>
  post("case/predict_one", { datasetId, caseId, features });
const addDiagnosticMessage = (caseId, message) =>
  post("case/add_diagnostic_message", { caseId, message });
const optimizeModel = (
  caseId,
  final_model,
  isAuto,
  hyperparametersTuningConfig,
  isregression,
  class_to_optimize
) =>
  post("case/optimizeModel ", {
    caseId,
    final_model,
    isAuto,
    hyperparametersTuningConfig,
    isregression,
    class_to_optimize,
  });
const getOptPlotData = (caseId) => post("case/getOptPlotData", { caseId });

// dataset routes
const removeFile = (userId, fileId, datasetId) =>
  del(
    `/dataset/remove_file?userId=${userId}&fileId=${fileId}&datasetId=${datasetId}`,
    {}
  );
const createDataset = (userId, datasetName, datasetDescription, signature) =>
  post("/dataset/create_dataset", {
    userId,
    datasetName,
    datasetDescription,
    signature,
  });
const duplicateDataset = (
  datasetId,
  datasetName,
  datasetDescription,
  signature
) =>
  post("/dataset/duplicate_dataset", {
    datasetId,
    datasetName,
    datasetDescription,
    signature,
  });
const hideDataset = (datasetId, boolean) =>
  post("/dataset/hide_dataset", { datasetId, boolean });
const unsubscribeDataset = (datasetId) =>
  del(`/dataset/unsubscribe_dataset?datasetId=${datasetId}`, {});
const fetchDataset = (datasetId) =>
  get(`/dataset/get_dataset?datasetId=${datasetId}`, {});
const fetchDatasetName = (datasetId) =>
  get(`/dataset/get_dataset_name?datasetId=${datasetId}`, {});
const editDataset = (datasetId, datasetName, datasetDescription) =>
  post("/dataset/edit_dataset", { datasetId, datasetName, datasetDescription });
const deleteDataset = (datasetId) =>
  del(`/dataset/delete_dataset?datasetId=${datasetId}`, {});
const getFilesInDataset = (datasetId) =>
  get(`/dataset/get_files?datasetId=${datasetId}`, {});
const getUsersInDataset = (datasetId) =>
  get(`/core/get_users?datasetId=${datasetId}`, {});
const getCustomColName = (allFeaturesObj) => getNewColName(allFeaturesObj);
const removeUserFromDataset = (datasetId, userIdToBeRemoved) =>
  post("/dataset/remove_user_from_dataset", { datasetId, userIdToBeRemoved });
const shareDataset = (userIds, datasetId, accessLevel) =>
  post("/dataset/share", { userIds, datasetId, accessLevel });
const setSignature = (datasetId, signature) =>
  post("/dataset/set_signature", { datasetId, signature });
const editFileDesc = (fileId, desc) =>
  post("/dataset/edit_file_desc", { fileId, desc });

export default {
  createNewUser,
  getAllUsers,
  getAllUsersAnon,
  ppplGetValuesForConfig,
  socket,
  auth,
  fetchDataForLandingPage,
  createAccount,
  sendResetEmail,
  resetPassword,
  checkResetExpiration,
  fetchAdminData,
  fetchSampleData,
  downloadFileFromS3,
  createQueue,
  sendMessage,
  receiveMessage,
  createCheckoutSession,
  createCreditsCheckoutSession,
  redirectStripePortal,
  enterProject,
  createProject,
  hideProject,
  unsubscribeProject,
  getUsersInProject,
  editProject,
  fetchDataAfterLogin,
  enterCase,
  getCurrentCaseStep,
  getCaseInfo,
  initializeCase,
  preprocConfigQC,
  preprocOpExecution,
  preprocUndo,
  preprocRedo,
  preprocRestore,
  preprocRecommendation,
  preprocAutopilot,
  editTableData,
  fetchTableData,
  fetchDataforPpplViz,
  preprocPostProcessing,
  updateModelEvalMetric,
  ensembleModel,
  selectModel,
  createModel,
  deleteModel,
  getModelInfo,
  startTuning,
  stopTuning,
  pauseTuning,
  resumeTuning,
  getDetailsForEvaluation,
  getDetailsForExplanation,
  getDetailsForUq,
  explainModel,
  quantifyUncertainty,
  preUploadPredictionFile,
  postUploadPredictionFile,
  getPredictionFiles,
  deletePredictionFile,
  predict,
  generateReport,
  deleteReport,
  enableAPI,
  disableAPI,
  finalizeCase,
  removeFile,
  createDataset,
  duplicateDataset,
  hideDataset,
  unsubscribeDataset,
  fetchDataset,
  fetchDatasetName,
  editDataset,
  getFilesInDataset,
  getUsersInDataset,
  getCustomColName,
  removeUserFromDataset,
  shareDataset,
  setSignature,
  editCase,
  getCollections,
  updateFavoriteCase,
  createNewCase,
  updateIsShared,
  getApiforApiPage,
  deleteApi,
  supportEmail,
  editFileDesc,
  deleteCase,
  fetchActivities,
  changeName,
  checkUsage,
  updateActive,
  deleteUser,
  extendTrial,
  changeTier,
  getUsersForAdmin,
  transferToExisting,
  transferToNew,
  duplicateCase,
  predictOne,
  deleteProject,
  deleteDataset,
  changePassword,
  resolveCustomer,
  addVideoToUser,
  addDiagnosticMessage,
  optimizeModel,
  getOptPlotData,
};
