import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import login1 from "../../../static/images/login1.svg";
import login2 from "../../../static/images/login2.svg";
import aaiLogo from "../../../static/images/SML-Logo-WordMark.svg";
import queryString from "query-string";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import BusyOverlay from "../../General/BusyOverlay";
import API from "../../../lib/api-store";
import { Alert } from "reactstrap";
import { CSSTransition } from "react-transition-group";
import { Input } from "reactstrap";

const TrialRegistration = ({ user, location, history }) => {
  const [newUser, setNewUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    phone: "",
    awsToken: "",
    region: "",
  });
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [phone, setPhone] = useState();
  const [isRedirected, setIsRedirected] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const regionOptions = ["Americas", "Europe", "Middle East", "Asia", "Africa"];

  const handleNewUserForm = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };
  const handlePhoneChange = (e) => {
    setPhone();
    let phone = e;
    setNewUser({ ...newUser, phone });
  };
  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const email = newUser.email.toLowerCase();
    const firstName = newUser.firstName;
    const lastName = newUser.lastName;

    let company = newUser.company;
    const phone = newUser.phone;
    const admin = false;
    const tier = "Trial";
    // trial/includeTrialDataset is harded coded as true for now, JM 3/3/2020
    const includeTrialDataset = true;
    const isTrialPage = true;
    const region = newUser.region;

    setMessage("");
    setIsBusy(true);
    API.createNewUser(
      email,
      firstName,
      lastName,
      company.name,
      company._id,
      phone,
      tier,
      includeTrialDataset,
      admin,
      isTrialPage,
      region
    ).then((r) => {
      setIsBusy(false);
      if (r.success) {
        // setUserPassword(r.rawPassword);
        // handleUserCreated();
        setSuccess(true);
        setMessage("Account created!");
        setIsRedirected(true);
      }
      if (r.error) {
        console.log(r.error);
        setSuccess(false);
        setMessage(<span dangerouslySetInnerHTML={{ __html: r.error }}></span>);
      }
    });
  };
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    setNewUser({ ...newUser, awsToken: parsed.token });
  }, []);
  if (user !== null) {
    if (user.last_access_date) {
      return <Redirect to="/main" />;
    }
    return <Redirect to="/datasets" />; //default landing page
  }
  if (isRedirected) {
    window.location.href = "https://www.speedwiseml.com/Demo-Thanks";
    return null;
  }
  return (
    <div className="login">
      {isBusy && <BusyOverlay />}
      <form className="login-form left" onSubmit={handleSubmit}>
        <img src={aaiLogo} className="login-product-logo" alt="" />
        <div className="login-product-header">
          Machine Learning For Everyone
        </div>
        {message && (
          <CSSTransition
            in={true}
            timeout={3000}
            classNames="alert"
            unmountOnExit
          >
            <Alert color={success ? "success" : "danger"} isOpen={true}>
              <FontAwesomeIcon
                icon={success ? "check" : "exclamation-triangle"}
              />{" "}
              {message}
            </Alert>
          </CSSTransition>
        )}
        <input
          className="login-textbox"
          name="email"
          type="email"
          placeholder="Email"
          onChange={handleNewUserForm}
          disabled={isVerifying}
          required
        />
        <input
          className="login-textbox in-row"
          name="firstName"
          type="firstName"
          placeholder="First Name"
          onChange={handleNewUserForm}
          disabled={isVerifying}
          required
        />
        <input
          className="login-textbox in-row"
          name="lastName"
          type="lastName"
          placeholder="Last Name"
          onChange={handleNewUserForm}
          disabled={isVerifying}
          required
        />
        <input
          className="login-textbox"
          name="company"
          type="company"
          placeholder="Company"
          // onChange={handleNewUserForm}
          onChange={(e) => {
            let companyData = { name: e.target.value, _id: null };
            // let companyData = e.target.value
            const { name, value } = e.target;
            setNewUser({ ...newUser, [name]: companyData });
          }}
          disabled={isVerifying}
          required
        />
        <div>
          <select
            required
            className="trial-region-select"
            name="region"
            onChange={handleNewUserForm}
          >
            <option value="" disabled selected>
              Choose your region
            </option>
            {regionOptions.map((x) => (
              <option value={x} key={x}>
                {x}
              </option>
            ))}
          </select>
        </div>

        <PhoneInput
          placeholder="Phone (optional)"
          value={phone}
          name="phone"
          type="phone"
          onChange={handlePhoneChange}
          className="login-textbox last-login-textbox"
          defaultCountry="US"
          id="trial-phone"
          disabled={isVerifying}
        />
        <button
          className="submit-button secondary-button login-button"
          type="submit"
        >
          {isVerifying ? "VERIFIED" : "SIGN UP"}
          <FontAwesomeIcon icon="sign-in-alt" />
        </button>
        <span
          className="blue-hyperlink"
          onClick={() => {
            history.push("/login");
          }}
        >
          Back to Login page
        </span>
      </form>

      <div className="img-crossfade right">
        <img className="bottom" src={login1} alt="login" />
        <img className="top" src={login2} alt="login" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.get("user") ? state.get("user").toJS() : null,
});

export default withRouter(connect(mapStateToProps)(TrialRegistration));
