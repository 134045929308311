import React, { Fragment } from 'react';


const CircleText = ({ text, color, className }) => (
	<Fragment>
		<span
			className={'circle-text ' + className}
			style={{ backgroundColor: (color || '#0077FF') + '33', color: color || '#0077FF' }}
		>
			{text}
		</span>
	</Fragment>
);

export default CircleText;
